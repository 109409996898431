<template>
    <div>
        <div class="row no-gutters hero-image hero-image__select-package d-flex justify-content-center">
            <div class="justify-content-center">
                <h1 v-text="lang.get('packages.select-your-package')"></h1>
                <p v-text="lang.get('packages.please-choose-the-level')"></p>
                <div class="cta pt-4">
                    <span class="d-block" v-text="lang.get('packages.Questions?')"></span>
                    <a href="https://calendly.com/jasonmphelps/dm-30min" target="_blank" class="btn btn-schedule d-inline-block" v-text="lang.get('packages.schedule-a-call')"></a>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="mb-3 mb-lg-5">
                <!--
                <div class="row mb-3 mb-md-4">
                    <div class="col-12 text-center">
                        <h2>{{ lang.get('auth.dont-just-read-the-book') }}</h2>
                        <h1 class="text-normal-case">{{ lang.get('auth.you-can-do-it') }}</h1>
                        <p class="text-lighter">{{ lang.get('auth.questions-email-us') }} <a href="mailto:info@disciplesmade.com">info@disciplesmade.com</a></p>
                        <h3 class="text-lighter mt-5"  v-html="lang.get('auth.not-ready-to-take-assessment', { url: 'https://www.giftpassionstory.com/' })"></h3>
                    </div>
                </div>
                -->
                <div class="row justify-content-center mb-3 mb-lg-4">
                    <div class="col-12">
                        <div class="free-account">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <h3 class="m-md-0 text-white mb-3 mb-md-0">{{ lang.get('packages.take-free-assessment') }}</h3>
                                </div>
                                <div class="col-12 col-md-6 text-md-right">
                                    <button @click="selectPackage('free')" class="button primary w-icon icon-right m-n2">
                                        {{ lang.get('packages.create-free-account') }}
                                        <span class="gps-icon arrow"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-4 mb-3 mb-lg-0 d-lg-flex flex-column">
                        <div class="card package thin flex-grow-1 d-flex flex-column">
                            <div class="card-header text-lg-center">
                                <h2 class="h1">{{ silverPackage }}</h2>
                            </div>
                            <div class="row">
                                <div class="col-12 justify-content-center">
                                    <div class="row mb-4">
                                        <div class="col-12 d-flex justify-content-center align-items-center">
                                            <img :src="`${assetUrl}/images/book-cover.png`" :title="lang.get('packages.find-your-place')" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 price justify-content-center" style="text-transform: uppercase;">
                                            {{ lang.get('packages.price') }}
                                            <div class="number">
                                                ${{ packageList[0].price }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <ul class="check primary text-lighter pl-0">
                                        <li>{{ lang.get('packages.downloadable-sermon-outlines') }}</li>
                                        <li>{{ lang.get('packages.downloadable-small-group-materials') }}</li>
                                        <li>{{ lang.get('packages.downloadable-small-group-director-kit') }}</li>
                                    </ul>
                                </div>
                            </div>
                            <button class="button primary x-large block" @click="selectPackage('silver')">{{ purchase }}</button>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 mb-3 mb-lg-0 d-lg-flex flex-column">
                        <div class="card package thin flex-grow-1 d-flex flex-column">
                            <div class="card-header text-lg-center">
                                <h2 class="h1">{{ goldPackage }}</h2>
                            </div>
                            <div class="row">
                                <div class="col-12 justify-content-center">
                                    <div class="row mb-4">
                                        <div class="col-12 d-flex justify-content-center align-items-center">
                                            <img :src="`${assetUrl}/images/book-cover.png`" :title="lang.get('packages.find-your-place')" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 price justify-content-center" style="text-transform: uppercase;">
                                            {{ lang.get('packages.price') }}
                                            <div class="number">
                                                ${{ packageList[1].price }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <ul class="check danger text-lighter pl-0">
                                        <li>{{ lang.get('packages.all-elements-of-silver') }}</li>
                                        <li>{{ lang.get('packages.1-year-admin-member-results') }}</li>
                                        <li>{{ lang.get('packages.one-30-minute-coaching-session') }}</li>
                                        <li>{{ lang.get('packages.downloadable-coach-dev-kit') }}</li>
                                    </ul>
                                </div>
                            </div>
                            <button class="button danger x-large block" @click="selectPackage('gold')">{{ purchase }}</button>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 d-lg-flex flex-column">
                        <div class="card package thin flex-grow-1 d-flex flex-column">
                            <div class="card-header text-lg-center">
                                <h2 class="h1">{{ platinumPackage }}</h2>
                            </div>
                            <div class="row">
                                <div class="col-12 justify-content-center">
                                    <div class="row mb-4">
                                        <div class="col-12 d-flex justify-content-center align-items-center">
                                            <img :src="`${assetUrl}/images/book-cover.png`" :title="lang.get('packages.find-your-place')" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 price justify-content-center" style="text-transform: uppercase;">
                                            {{ lang.get('packages.price') }}
                                            <div class="number">
                                                ${{ packageList[2].price }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <ul class="check success text-lighter pl-0">
                                        <li>{{ lang.get('packages.all-elements-of-gold') }}</li>
                                        <li>{{ lang.get('packages.2-years-admin-functionality') }}</li>
                                        <li>{{ lang.get('packages.four-30-minute-coaching-session') }}</li>
                                    </ul>
                                </div>
                            </div>
                            <button class="button success x-large block" @click="selectPackage('platinum')">{{ purchase }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PackageSelection",
        props: {
            packageList: Array,
            assetUrl: String,
        },
        mounted() {
            window.scrollTo(0,0);
        },
        computed: {
            lang() {
                return window.lang
            },
            silverPackage() {
                return `${this.lang.get('packages.silver')} ${this.lang.get('packages.package')}`
            },
            goldPackage() {
                return `${this.lang.get('packages.gold')} ${this.lang.get('packages.package')}`
            },
            platinumPackage() {
                return `${this.lang.get('packages.platinum')} ${this.lang.get('packages.package')}`
            },
            purchase() {
                return `${this.lang.get('packages.purchase')}`
            }
        },
        methods: {
            selectPackage(name) {
                this.$emit('select', name);
            }
        }
    }
</script>

<style scoped>

</style>
